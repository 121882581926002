/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"
import Cookies from "./cookies"
import "typeface-roboto"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div class="min-h-screen flex flex-col">
        <div class="flex-grow p-8">
          <main>{children}</main>
          <Cookies />
        </div>
        <div class="bg-gray-700 text-white p-8">
          <div class="w-full block lg:w-auto">
            <div class="flex flex-row space-between">
              <div>
                <div class="text-base">&copy; JCI Dendermonde</div>
                <div class="text-sm">Adres: Emile Bockstaellaan 193, 1020 Brussel</div>
                <div class="text-sm">Ondernemingsnummer: BE 0415.456.047</div>
                <div class="text-sm mt-8">
                  <Link to="/disclaimer">
                    <a>
                      Disclaimer
                    </a>
                  </Link>
                </div>
                <div class="text-sm">
                  <Link to="/privacy">
                      <a>
                        Privacy policy
                      </a>
                  </Link>
                </div>
              </div>
              <div class="ml-24">
                <div class="text-sm"><a href="https://jci.vlaanderen/">JCI Vlaanderen</a></div>
                <div class="text-sm"><a href="https://www.jci.be/">JCI België</a></div>
                <div class="text-sm"><a href="https://jci.cc/">Junior Chamber International</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
