// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import Logo from "./logo"
import { Link } from "gatsby"
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';


const Header = ({ siteTitle }) => {
  const width = typeof window !== `undefined` ? window.screen.width : 641;
  const [isShowable, setIsShowable] = useState(width > 640);
  const menu = <div className="w-full block flex-grow flex md:items-center w-auto">
  <div className="text-sm md:flex-grow mt-2 mb-2 ml-2">
    <span className="mr-8 hidden md:inline"><Link to="/"><Logo></Logo></Link></span>
    <span className="menu-item block mt-4 md:inline-block md:mt-0 text-gray-700 hover:text-gray-500 mr-4">
      <Link to="/dit-is-jci">
        <a className="block mt-4 mb-2 px-3 py-1 rounded-md md:inline-block md:mt-0 text-grey-200 font-semibold hover:text-white hover:bg-blue-700 mr-4">
          Over ons
          </a>
      </Link>
    </span>
    <span className="menu-item block mt-4 md:inline-block md:mt-0 text-gray-700 hover:text-gray-500 mr-4">
      <Link to="/projecten">
        <a className="block mt-4 mb-2 px-3 py-1 rounded-md md:inline-block md:mt-0 text-grey-200 font-semibold hover:text-white hover:bg-blue-700 mr-4">
          Projecten
        </a>
      </Link>
    </span>
    <span className="menu-item block mt-4 md:inline-block md:mt-0 text-gray-700 hover:text-gray-500 mr-4">
      <Link to="/opleidingen">
        <a className="block mt-4 mb-2 px-3 py-1 rounded-md md:inline-block md:mt-0 text-grey-200 font-semibold hover:text-white hover:bg-blue-700 mr-4">
          Opleidingen
        </a>
      </Link>
    </span>
    <span className="menu-item block mt-4 md:inline-block md:mt-0 text-gray-700 hover:text-gray-500">
      <Link to="/contact">
        <a className="block mt-4 mb-2 px-3 py-1 rounded-md md:inline-block md:mt-0 text-grey-200 font-semibold hover:text-white hover:bg-blue-700 mr-4">
          Contact
        </a>
      </Link>
    </span>
  </div>
  <div className="flex">
    <a className="ml-2 mt-8 social-icon" href="https://www.facebook.com/jcidendermonde"><FaFacebook></FaFacebook></a>
    <a className="ml-2 mt-8 social-icon"href="https://www.instagram.com/jcidendermonde/"><FaInstagram></FaInstagram></a>
  </div>
</div>;
const clickMe = () => { setIsShowable(!isShowable);  }
  return <nav id="header">
    <div className="headerize flex bg-white border-b border-gray-200 fixed top-0 inset-x-0 z-100 h-24 items-center shadow">
      <div className="w-full max-w-screen-xl relative mx-auto px-6">
        <div className="flex items-center -mx-6">
          <div className="block lg:hidden md:hidden ml-4 mt-4 mb-4">
            <button onClick={clickMe} className="flex items-center px-3 py-2 border rounded text-blue-400 border-blue-400 hover:text-blue-700 hover:border-blue-700">
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </button>
          </div>
          { isShowable ? menu : ''}
        </div>
      </div>
    </div>
  </nav>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
